import styles from './index.module.scss'

const SubmitBtn = ({ title, icon }) => {
  return (
    <button className={styles.btn} type="submit">
      <span className={styles.icon}>{icon}</span> <span>{title}</span>
    </button>
  )
}

export default SubmitBtn
