import { Container, Row, Col, Form } from 'react-bootstrap'
import styles from './index.module.scss'
import Footer from '../../components/footer'
import Header from '../../components/header'
import roomBackground from '../../images/room/01.jpg'
import ImageGallery from 'react-image-gallery'
import roomImage from '../../images/room/01-mirror.jpg'
import roomImage2 from '../../images/room/02.jpg'
import roomImage3 from '../../images/room/03.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MoonlightText from '../../images/room/moonlight.png'
import DatePicker from 'react-datepicker'
import { useState } from 'react'
import SubmitBtn from '../../components/submit-btn'

const images = [
  {
    original: roomImage,
    thumbnail: roomImage,
  },
  {
    original: roomImage2,
    thumbnail: roomImage2,
  },
  {
    original: roomImage3,
    thumbnail: roomImage3,
  },
]

const RoomDetails = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  return (
    <>
      <Header />
      <Container
        style={{
          backgroundImage: `url(${roomBackground})`,
          height: '60vh',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Row className="bikham justify-content-center align-items-center h-100">
          <Col className={styles.heading}>
            <h2>Наслада за душата</h2>
          </Col>
        </Row>
      </Container>

      <div
        className={styles.roomSlider}
        style={{
          backgroundImage: `url(${MoonlightText})`,

          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className={styles.roomImages} md={10}>
              <h2 className="alice">Стандартна двойна стая</h2>
              <Row className="justify-content-center">
                <Col lg={7}>
                  <ImageGallery
                    showFullscreenButton={false}
                    showNav={false}
                    showPlayButton={false}
                    lazyLoad={true}
                    items={images}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <Container as={Form} className="mb-5 alice">
        <Row>
          <Col className={styles.RoomDetails} lg={5}>
            <h2>Детайли</h2>

            <div className={styles.capacity}>
              <div className={styles.detailsTitle}>
                <p>
                  <FontAwesomeIcon icon="user" /> Възрастни: 2
                </p>
              </div>
            </div>

            <div className={styles.comfort + ' pt-2'}>
              <div className={styles.detailsTitle}>
                <p>
                  <FontAwesomeIcon icon="glass-martini" /> Удобства:
                </p>
              </div>

              <div className={styles.detailsText}>
                <p>
                  Кът за сядане, TV с плосък екран, Телефон за връзка с
                  рецепция, Тоалетни принадлежности, Ел. кана за вода, Кабелен и
                  безжичен интернет, Комфортен матрак, Мини бар, Сейф, Сешоар,
                  Тераса, Халат и чехли за СПА зона
                </p>
              </div>
            </div>

            <div className={[styles.viewOptions, 'd-flex', 'pt-2'].join(' ')}>
              <div className={styles.detailsTitle}>
                <FontAwesomeIcon icon="eye" /> Изглед:
              </div>

              <Form.Group className={styles.viewCheckboxes + ' pb-2'}>
                <Form.Check label="Море" name="sea" feedbackType="invalid" />
                <Form.Check
                  label="Градина"
                  name="garden"
                  feedbackType="invalid"
                />
                <Form.Check label="Басейн" name="pool" feedbackType="invalid" />
              </Form.Group>
            </div>

            <div className={[styles.viewOptions, 'd-flex', 'pt-2'].join(' ')}>
              <div className={styles.detailsTitle}>
                <FontAwesomeIcon icon="eye" /> Тип легло:
              </div>

              <Form.Group className="pb-2">
                <Form.Check
                  label="Спалня"
                  name="bedroom"
                  feedbackType="invalid"
                />
                <Form.Check
                  label="Две отделни легла"
                  name="garden"
                  feedbackType="invalid"
                />
              </Form.Group>
            </div>

            <div className={[styles.viewOptions, 'd-flex', 'pt-2'].join(' ')}>
              <div className={styles.detailsTitle}>
                <FontAwesomeIcon icon={['far', 'money-bill-alt']} /> Цена: 220
                лв.
              </div>
            </div>
          </Col>

          <Col lg={7}>
            <Row>
              <Col className={styles.dateRange}>
                <h2>Престой</h2>

                <Row>
                  <Col>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showMonthDropdown
                      startDate={startDate}
                      minDate={startDate}
                      endDate={endDate}
                      inline
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      showMonthDropdown
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      inline
                    />
                    <SubmitBtn title="Резервирай" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default RoomDetails
