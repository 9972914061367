import styles from './index.module.scss'
import { useState, useEffect } from 'react'
import Car from '../../components/car'
import { Container, Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Header from '../../components/header'
import Footer from '../../components/footer'
import TransferBackground from '../../images/transfer/car-bg.jpg'
import CarRentPicture from '../../images/transfer/car-rent.jpg'
import CarRentPicture2 from '../../images/transfer/car-rent2.jpg'
import CarRentPicture3 from '../../images/transfer/car-rent3.jpg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ExclMarkIcon from '../../images/transfer/exclamation-icon.svg'
import { Config } from './../../services/config.service'
import Loading from '../../components/loading'

const TransferPage = () => {
  const [categories, setCategories] = useState([])
  const [allCars, setAllCars] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [brands, setBrands] = useState([])
  const [price, setPrice] = useState(0)
  const [seats, setSeats] = useState(2)
  const [selectedBrand, setSelectedBrand] = useState('0')
  const [selectedCategory, setSelectedCategory] = useState('0')
  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState({
    category: false,
    model: false,
    seats: false,
  })

  const clearError = (field) => {
    let formErrors = errors
    formErrors[field] = false
    setErrors(formErrors)
  }

  const filterAutos = (allCars, seats) => {
    let totalAutos = allCars.filter((car) => +car.seats >= +seats)

    let categories = {}
    for (let car of totalAutos) {
      categories[car.category.id] = { ...car.category, cars: [] }
    }

    categories = Object.values(categories)

    categories = categories.map((category) => {
      category.cars = totalAutos
        .filter((car) => car.category?.id === category?.id)
        .slice()
      return category
    })
    setCategories(categories)

    const models = categories.reduce((prev, current) => {
      return [...prev, ...current.cars]
    }, [])

    setBrands(models)
  }

  useEffect(() => {
    setLoading(true)
    fetch(Config.API + 'transfer')
      .then((res) => res.json())
      .then((data) => {
        let allCars = data.data

        setAllCars(allCars)

        filterAutos(allCars, seats)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }, [seats])

  const handleChangeSeats = (event) => {
    setSeats(event.target.value)
    clearError('seats')

    filterAutos(allCars, event.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const formErrors = {
      type: false,
      model: false,
      seats: false,
    }

    if (+selectedBrand === 0) {
      formErrors.model = true
    }

    if (+selectedCategory === 0) {
      formErrors.category = true
    }

    setLoading(true)

    setErrors(formErrors)
    if (Object.keys(formErrors).filter((name) => formErrors[name]).length) {
      setLoading(false)
      return
    }

    let selectedDate = startDate.setHours(0, 0, 0, 0)
    selectedDate = +selectedDate / 1000

    const url = new URL(Config.API + 'transfer')
    url.search = new URLSearchParams({
      car: selectedBrand,
      seats,
      date: selectedDate,
    })
  }

  const handleCategoryChange = (e) => {
    e.preventDefault()
    filterAutos(allCars, seats)

    const selected = ~~e.target.value
    const result =
      selected > 0
        ? categories.find((category) => category.id === selected).cars?.slice()
        : allCars?.slice()
    setSelectedCategory(selected)
    setPrice(0)
    setBrands(result)
    setSelectedBrand('0')
    clearError('category')
  }

  const handleCarChange = (e) => {
    e.preventDefault()

    const car = allCars?.find((car) => car.id === +e.target.value)
    setPrice(car.price)
    setSeats(+car.seats)
    setSelectedBrand(car.id)
    clearError('model')
  }

  const carSelectHandler = (e, carId) => {
    e.preventDefault()

    const car = allCars?.find((car) => car.id === carId)
    setPrice(car.price)
    setSeats(+car.seats)
    setSelectedCategory(car.category.id.toString())
    setSelectedBrand(car.id.toString())

    return false
  }

  const renderCars = (cars) => {
    return cars?.map((car) => (
      <Col key={car.id}>
        <Car
          id={car.id}
          brand={car.brand}
          year={car.year}
          carImage={car.image}
          imageTitle={car.image}
          carDetailsImages={car.images}
          onClick={carSelectHandler}
        />
      </Col>
    ))
  }

  return (
    <>
      <Header />
      <Container
        fluid="md"
        as="section"
        style={{
          backgroundImage: `url(${TransferBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Row>
          <Col className={styles.transferTitle + ' text-center'}>
            <h1>Трансфер</h1>

            <h3>Резервация на трансфер от/до летище</h3>
          </Col>
        </Row>

        <Row className={styles.transferText + ' justify-content-center'}>
          <Col lg={10} className={styles.transferEntry + ' alice'}>
            <p>
              Нашият хотел Ви предлага възможността да наемете автомобил от и до
              летището. <br /> На разположение имате 11 вида автомобили, които
              можете да резервирате. <br /> Три вида спортни луксозни
              автомобили, четири луксозни автомобила тип седан и четири модела
              тип ван.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <Row className={styles.carRent + ' g-0'}>
              <Col>
                <img src={CarRentPicture} alt="car-rent" />
              </Col>

              <Col>
                <img src={CarRentPicture2} alt="car-rent" />
              </Col>

              <Col>
                <img src={CarRentPicture3} alt="car-rent" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col className={styles.carsTitle + ' alice'}>
            <h2>
              Автомобили
              {loading && <Loading />}
            </h2>
          </Col>
        </Row>
      </Container>

      {categories?.map((category) => {
        return (
          <div key={category.id}>
            <Container>
              <Row className="text-center mt-5">
                <Col className={[styles.typeCars, 'alice']}>
                  <h2>{category.title}</h2>
                  <div className={styles.separator} />
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className={styles.addBorder}>
                {renderCars(category.cars)}
              </Row>
            </Container>
          </div>
        )
      })}
      <Container className={styles.bookCar}>
        <Row className="justify-content-center">
          <Col lg={8}>
            <Row className={styles.formTitle + ' alice'}>
              <Col as="h1">Резервирай</Col>
            </Row>
            <Form
              noValidate
              onSubmit={handleSubmit}
              className={styles.bookingForm}
            >
              <Row>
                <Col xs={6}>
                  <Row>
                    <Col className={styles.dateFiled}>
                      <Form.Label>Дата:</Form.Label>{' '}
                      <Form.Control
                        disabled
                        type="text"
                        value={
                          startDate &&
                          `${startDate.toLocaleDateString('bg-BG')}`
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        minDate={new Date()}
                        disabled={loading && true}
                        inline
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col className={styles.seats}>
                      <Form.Label>Брой места</Form.Label>

                      <Form.Control
                        value={seats}
                        type="number"
                        min="1"
                        max="8"
                        disabled={loading && true}
                        className={errors.seats ? 'is-invalid' : null}
                        onChange={handleChangeSeats}
                      />

                      <Form.Control.Feedback
                        style={{ marginTop: '4em' }}
                        type="invalid"
                      >
                        Моля въведете коректно броя на пътниците
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Тип автомобил:</Form.Label>
                      <Form.Select
                        onChange={handleCategoryChange}
                        className={errors.category ? 'is-invalid' : null}
                        aria-label="Тип автомобил"
                        value={selectedCategory}
                        disabled={loading && true}
                        required
                      >
                        <option value={0}>Всички</option>
                        {categories?.map((category, i) => (
                          <option key={category.id} value={category.id}>
                            {category.title}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback
                        type="invalid"
                        className={styles.field_error}
                      >
                        Моля, изберете категория.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Марка:</Form.Label>
                      <Form.Select
                        onChange={handleCarChange}
                        className={errors.model ? 'is-invalid' : null}
                        aria-label="Марка"
                        value={selectedBrand}
                        disabled={loading && true}
                        required
                      >
                        <option value={0}>Избери модел</option>
                        {brands?.map((car, i) => (
                          <option
                            key={i}
                            value={car.id}
                          >{`${car.brand} ${car.model}`}</option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback
                        type="invalid"
                        className={styles.field_error}
                      >
                        Моля, изберете модел автомобил.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className={styles.priceSubmit + ' alice'}>
                <Col>
                  <p>Цена: {price ? price + 'лв.' : '--'}</p>
                </Col>

                <Col className={styles.seats}>
                  <button type="submit">Резервирай</button>
                </Col>

                <Col className={[styles.warning, 'mt-4'].join(' ')} xs={12}>
                  <p>
                    <img src={ExclMarkIcon} alt="exclamation-mark-icon" /> Между
                    22:00 и 08:00 ще се плаща с 20% повече от стандартното
                  </p>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default TransferPage
