import Header from '../../components/header'
import Footer from '../../components/footer'
import styles from './index.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import DashboardBackground from '../../images/dashboard-bg2.jpg'
import UserContext from '../../Context'
import { useContext } from 'react'

const Dashboard = () => {
  const { user, logOut } = useContext(UserContext)

  const isAdmin = user?.roles?.includes('admin')

  const userRole = isAdmin ? 'Администратор - ' : 'Здравейте, '
  const greeting = (
    <h2>
      {userRole} {user.name} {user.surname} !
    </h2>
  )

  return (
    <>
      <Header />
      <section
        className={styles.body}
        style={{
          backgroundImage: `url(${DashboardBackground})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: '60vh',
        }}
      >
        <Container>
          <Row>
            <Col className="text-center mt-5 alice">
              <div className={styles.greeting}>{greeting}</div>

              <div className={styles.bookingsTitle}>
                <h1>Вашите резервации</h1>

                <p
                  style={{
                    fontWeight: '600',
                    fontSize: '20px',
                    color: '#24815c',
                  }}
                >
                  В момента нямате резервации !
                </p>

                {/*  to be changed after the db is ready */}
              </div>

              <div className={styles.exit}>
                <button onClick={logOut}>ИЗХОД</button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Dashboard
