import Footer from '../../components/footer'
import Header from '../../components/header'
import styles from './index.module.css'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import ContactPhoto from '../../images/contacts.jpg'
import LocationIcon from '../../images/icons/icon1.svg'
import LetterIcon from '../../images/icons/icon3.svg'
import PhoneIcon from '../../images/icons/icon2.svg'
import GoogleMaps from '../../components/google-maps'
import { Config } from './../../services/config.service'
import { useState } from 'react'
import Loading from '../../components/loading'
import Title from '../../components/transparent-title'

const Contact = () => {
  const [loading, setLoading] = useState(false)
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [contactMessage, setContactMessage] = useState('')
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('success')
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const formErrors = {
      name: '',
      email: '',
      phone: '',
      message: '',
    }

    if (contactName?.length <= 1) {
      formErrors.name = 'Моля, въведете коректно Вашето име'
    }

    if (contactEmail?.length <= 1 || !contactEmail.match(/@/)?.length) {
      formErrors.email = 'Моля, въведете коректно Вашият електронен адрес'
    }

    if (contactPhone?.length <= 1) {
      formErrors.phone = 'Моля, въведете коректно Вашият телефонен номер'
    }

    if (contactMessage?.length <= 1) {
      formErrors.message = 'Моля, въведете коректно Вашето съобщение към нас'
    }

    setLoading(true)
    setMessage('')

    setErrors(formErrors)
    if (Object.keys(formErrors).filter((name) => formErrors[name]).length) {
      setLoading(false)
      setMessage('Моля, проверете полетата отново')
      setMessageType('error')
      return
    }

    fetch(Config.API + 'contacts', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify({
        name: contactName,
        email: contactEmail,
        phone: contactPhone,
        message: contactMessage,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw response
        }
        setMessage('Ще се свържем с Вас възможно най-скоро!')
        setMessageType('success')
        setContactName('')
        setContactEmail('')
        setContactPhone('')
        setContactMessage('')
        setLoading(false)
      })
      .catch((error) => {
        setMessageType('error')
        setMessage('Неуспешно изпратено съобщение! Опитайте отново')
        setLoading(false)

        error.json().then((error) => {
          setMessage(error?.message)
          const errors = error?.errors ?? []
          setErrors(
            Object.keys(errors).reduce((prev, key) => {
              prev[key] = errors[key].find((item, index) => index === 0)
              return prev
            }, {}),
          )
          console.log('errors!', errors)
        })
      })
  }

  const clearError = (field) => {
    let formErrors = errors
    formErrors[field] = false
    setErrors(formErrors)
  }

  return (
    <>
      <Header />
      <section>
        <Container fluid>
          <Row>
            <Col
              style={{
                backgroundImage: `url(${ContactPhoto})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                position: 'relative',
                height: '80vh',
                minHeight: '400px',
                padding: '0',
              }}
            >
              <Title middleTitle='Контакти'/>
            </Col>
          </Row>
        </Container>
      </section>

      <Container fluid="lg" as="section" className={styles.containerForm}>
        <Row className="justify-content-center">
          <Col xs={12} md={6} className={styles.contactInfo + ' alice'}>
            <div className={styles.address}>
              <p>
                <img src={LocationIcon} alt="gps-icon" />
                MOONLIGHT Hotel &amp; Spa
              </p>

              <p>Варна</p>

              <p>ул. Димитър Икономов 19</p>

              <p>България</p>
            </div>

            <div className={styles.phoneEmail}>
              <p>
                <img src={LetterIcon} alt="letter-icon" />
                moonlight_hotel_spa@gmail.com
              </p>

              <p>
                <img src={PhoneIcon} alt="phone-icon" /> 0898 777 333
              </p>
            </div>
          </Col>

          <Col xs={12} md={6} className={styles.formWrapper}>
            <Form noValidate onSubmit={handleSubmit} className={styles.form}>
              {!message?.length ? null : (
                <Row>
                  <Col className="mb-3">
                    <Alert
                      onClose={() => setMessage(null)}
                      dismissible
                      variant={messageType === 'success' ? 'success' : 'danger'}
                    >
                      {message}
                    </Alert>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Control
                    placeholder="Име:"
                    value={contactName}
                    disabled={loading}
                    className={errors.name ? 'is-invalid' : null}
                    onChange={(event) => {
                      setContactName(event.target.value)
                      clearError('name')
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={styles.field_error}
                  >
                    {errors.name}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Control
                    type="email"
                    placeholder="E-mail:"
                    value={contactEmail}
                    disabled={loading}
                    className={errors.email ? 'is-invalid' : null}
                    onChange={(event) => {
                      setContactEmail(event.target.value)
                      clearError('email')
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={styles.field_error}
                  >
                    {errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Control
                    type="tel"
                    placeholder="Телефон:"
                    value={contactPhone}
                    disabled={loading}
                    className={errors.phone ? 'is-invalid' : null}
                    onChange={(event) => {
                      setContactPhone(event.target.value)
                      clearError('phone')
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={styles.field_error}
                  >
                    {errors.phone}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Control
                    as="textarea"
                    type="tel"
                    placeholder="Съобщение:"
                    style={{ height: '150px' }}
                    value={contactMessage}
                    disabled={loading}
                    className={errors.message ? 'is-invalid' : null}
                    onChange={(event) => {
                      setContactMessage(event.target.value)
                      clearError('message')
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={styles.field_error}
                  >
                    {errors.message}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <button type="submit" disabled={loading ? true : null}>
                {loading ? <Loading /> : null}
                Изпрати &#8594;
              </button>
            </Form>
          </Col>
        </Row>
      </Container>

      <Col>
        <GoogleMaps
          title="Maps"
          googleUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2907.4655332072343!2d27.932910915767593!3d43.220698288307155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a4543dfa396535%3A0x7944352e072de44e!2sul.%20%22Dimitar%20Ikonomov%22%2019%2C%209010%20Levski%2C%20Varna!5e0!3m2!1sen!2sbg!4v1641993419888!5m2!1sen!2sbg"
          width="100%"
          height="700"
          loading="lazy"
        />
      </Col>
      <Footer />
    </>
  )
}

export default Contact
