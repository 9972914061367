import styles from './index.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import FlatPhoto from '../../images/photo_5.jpg'
import StudioPhoto from '../../images/photo_6.jpg'
import DoubleRoom from '../../images/photo_7.jpg'

const RoomsAndFlats = ({ typeRoom }) => {
  return (
    <section className={styles.roomsAndFlats}>
      <Container fluid>
        <Row className="text-center alice">
          <Col className={styles.roomsTitle + ' p-4'}>
            <h1>Стаи &amp; Апартаменти</h1>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-center p-3">
          <div
            style={{
              maxWidth: '10px',
              height: '70px',
              borderLeft: '10px solid #ac9788',
            }}
          />
        </Row>
      </Container>

      <Container className={styles.rooms}>
        <Row>
          <Col lg={4} className={styles.room}>
            <div>
              01 <span className={styles.separator} />
            </div>
            <img src={FlatPhoto} alt="flat" />
            <p>Апартаменти</p>
          </Col>

          <Col lg={4} className={styles.room}>
            <div>
              02 <span className={styles.separator} />
            </div>
            <img src={StudioPhoto} alt="studio" />
            <p>Студио</p>
          </Col>

          <Col lg={4} className={styles.room}>
            <div>
              03 <span className={styles.separator} />
            </div>
            <img src={DoubleRoom} alt="double-room" />
            <p>Стандартна двойна стая</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default RoomsAndFlats
