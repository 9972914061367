import './app.scss'
import UserContext from './Context'
import { useState, useEffect } from 'react'

function App(props) {
  const [user, setUser] = useState(null)

  const logIn = (user) => {
    setUser({
      ...user,
      loggedIn: true,
    })
  }

  const logOut = () => {
    localStorage.clear()

    setUser({
      loggedIn: false,
    })
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user) {
      setUser({
        ...user,
        loggedIn: true,
      })
    } else {
      logOut()
    }
  }, [])

  return (
    <UserContext.Provider value={{ user, logIn, logOut }}>
      {props.children}
    </UserContext.Provider>
  )
}

export default App
