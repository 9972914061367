import styles from './index.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import Logo from '../../images/LOGO.svg'
import fbIcon from '../../images/fb.svg'
import instagramIcon from '../../images/instagram.svg'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.moonlightLogo}>MOONLIGHT</div>

      <Container>
        <Row className="justify-content-center">
          <Col
            lg={8}
            className={
              styles.footerContent +
              ' d-flex justify-content-between align-items-center'
            }
          >
            <div>
              <img with={150} src={Logo} alt="logo" />
            </div>

            <div className={styles['footer-entry']}>
              <p>КОНТАКТИ:</p>
              <p>тел.: 0898 777 333</p>
              <p>адрес: гр. Варна, ул. "Димитър Икономов" 19</p>
              <p>moonlight_hotel_spa@gmail.com</p>
            </div>

            <div className={styles.social}>
              <a
                href="https://www.facebook.com/advanceacademy.bg/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="pb-3" src={fbIcon} alt="facebook-icon" />
              </a>

              <a
                href="https://www.instagram.com/advance_academy/?hl=bg"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagramIcon} alt="instagram-icon" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
