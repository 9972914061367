import Header from '../../components/header'
import Footer from '../../components/footer'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './index.module.css'
import introBackground from '../../images/photo_1.jpg'
import { Carousel } from 'react-responsive-carousel'
import './carousel.css'
import MoonlightText from '../../images/moonlight-text.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useState } from 'react'
import SubmitBtn from '../../components/submit-btn'
import HotelPhoto from '../../images/photo_2.jpg'
import AboutHotelPhoto from '../../images/aboutHotel.jpg'
import UmbrellaPhoto from '../../images/photo_3.jpg'
import PoolText from '../../images/poolsText.jpg'
import SpaPhoto from '../../images/photo_4.jpg'
import SpaText from '../../images/spaText.jpg'
import PodpisPhoto from '../../images/podpis.svg'
import RestaurantBackground from '../../images/restaurant-background.jpg'
import RestaurantPhoto from '../../images/restaurant2.jpg'
import SeeMore from '../../components/see-more-btn'
import ShrimpsPhoto from '../../images/shrimps.jpg'
import SportBarPhoto from '../../images/sport_bar.jpg'
import Joy from '../../components/joy-slogan'
import RoomsAndFlats from '../../components/room-and-flats'

const Home = () => {
  const [startDate, setStartDate] = useState(new Date())
  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <Header />
      <main className={styles.main}>
        <section
          style={{
            backgroundImage: `url(${introBackground})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          className={styles.intro}
        >
          <Container fluid>
            <Row>
              <Carousel
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                autoPlay={false}
              >
                <Col className={styles.moonlightWrapper}>
                  <div className={styles.moonlightTransparent}>
                    <img src={MoonlightText} alt="moonlight-text" />
                  </div>

                  <span className="bikham">Наслада за душата</span>
                  <span>Hotel &amp; Spa</span>
                </Col>

                <div></div>
                <div></div>
                <div></div>
              </Carousel>
            </Row>
          </Container>

          <section className={styles.bookingContainer}>
            <Container className={styles.bookingSection}>
              <Row
                as="form"
                onSubmit={handleSubmit}
                className="justify-content-center alice"
              >
                <Col className={styles.dates} lg={2}>
                  <p>Пристигане</p>
                  <DatePicker
                    dateFormat="MM.dd.yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </Col>

                <Col className={styles.dates} lg={2}>
                  <p>Заминаване</p>
                  <DatePicker
                    dateFormat="MM.dd.yyyy"
                    className={styles.datePicker}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </Col>

                <Col className={styles.personsCount} lg={1}>
                  <p>Възрастни</p>
                  <input type="number" min="1" max="99" />
                </Col>

                <Col className={styles.personsCount} lg={1}>
                  <p>Деца</p>
                  <input type="number" min="0" max="99" />
                </Col>

                <Col lg={1}>
                  <p style={{ opacity: '0' }}>book</p>
                  <SubmitBtn title="Резервирай" />
                </Col>
              </Row>
            </Container>
          </section>
        </section>

        <section className={styles.aboutHotel}>
          <Container>
            <Row className="justify-content-center">
              <Col className={styles.hotelPhoto}>
                <img src={HotelPhoto} alt="hotel" />
              </Col>
            </Row>

            <Row>
              <Col lg={4} className={styles.aboutHotelImage}>
                <img src={AboutHotelPhoto} alt="hotelText" />
              </Col>
            </Row>

            <Row className="justify-content-center align-items-center">
              <Col
                as="article"
                className={styles.articleEntry + ' alice'}
                lg={6}
              >
                <p>
                  Хотел Moonlight Hotel &amp; Spa е разположен покрай
                  крайбрежието на красива Варна. Очарователна гледка, лек бриз и
                  мирис на море вдъхновяват за едно незабравимо изживяване. В
                  обширната територия от открити и закрити басейни и СПА зона
                  може да се намери място за всеки вкус, възраст и настроение.{' '}
                  <br />
                  Очакваме Ви за една пълноценна почивка!
                </p>
              </Col>
              <Col className={styles.manager + ' bikham'} lg={3}>
                <img src={PodpisPhoto} alt="podpis" />
                <p>Генерален мениджър</p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={styles.spa}>
          <Container fluid>
            <Row className="justify-content-between">
              <Col className={styles.leftColumn} lg={1}></Col>

              <Col className="pt-5" lg={8}>
                <Row className="align-items-center">
                  <Col className={styles.umbrella} lg={6}>
                    <img src={UmbrellaPhoto} alt="umbrella-sunset" />
                  </Col>

                  <Col className={styles.poolEntry + ' alice'} lg={6}>
                    <Col lg={7}>
                      <img width="300px" src={PoolText} alt="pool-text" />
                    </Col>
                    <p>
                      На Ваше разположение са 2 големи открити минерални
                      басейна, 2 открити джакузита, 1 детски басейн и един голям
                      вътрешен басейн с минерална вода.
                    </p>
                  </Col>
                </Row>

                <Row className="align-items-center">
                  <Col className={styles.spaEntry + ' alice'} lg={6}>
                    <Col>
                      <img src={SpaText} alt="pool-text" />
                    </Col>
                    <p>
                      Възстановяване на тялото и ума, подобряване на
                      настроението и укрепване на организма са нашата мисия.
                      Модерният ни СПА център предлага възможности за почивка и
                      отдих.
                    </p>
                  </Col>

                  <Col className={styles.umbrella} lg={6}>
                    <img src={SpaPhoto} alt="spa" />
                  </Col>
                </Row>
              </Col>

              <Col className={styles.rightColumn} lg={1}></Col>
            </Row>
          </Container>
        </section>

        <RoomsAndFlats />

        <section
          style={{
            backgroundImage: `url(${RestaurantBackground})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          className={styles.restaurant}
        >
          <Container>
            <Row className="text-center alice">
              <Col className="pt-5" as="h1">
                Ресторант &amp; хранене
              </Col>
            </Row>

            <Row className="justify-content-center pb-1">
              <div
                style={{
                  maxWidth: '10px',
                  height: '70px',
                  borderLeft: '10px solid #41342a',
                }}
              />
            </Row>

            <Row className="justify-content-center">
              <Col className={styles.restaurantPhoto}>
                <img src={RestaurantPhoto} alt="restaurant" />
              </Col>
            </Row>

            <Row className={styles.restaurantEntry}>
              <Col
                lg={7}
                style={{ maxWidth: '57.5%' }}
                className="mb-3 text-center alice"
              >
                <p>Хотелът предлага</p>
                <span style={{ width: '80px' }} className={styles.separator} />

                <p>
                  Два ресторанта от които единия е автентичен Японски ресторант
                  с екзотична и нетрадиционна кухня и два дневни бара.
                </p>

                <p>
                  В традиционния ресторант можете да се възползвате от закуска и
                  вечеря на бок маса включваща разнообразие от ястия подходящи,
                  както за различни хранителни режими, така и за ценителите на
                  изобилието.
                </p>

                <SeeMore link="/restaurant" />
              </Col>

              <Col>
                <img src={ShrimpsPhoto} alt="shrimps" />
              </Col>
            </Row>
          </Container>
        </section>

        <section className={styles.sportBar}>
          <Container className="mb-6">
            <Row>
              <Col lg={7}>
                <img src={SportBarPhoto} alt="sport-bar" />
              </Col>

              <Col
                className={[
                  styles.restaurantEntry,
                  styles.barEntry,
                  'alice',
                  'text-center',
                ]}
              >
                <p>
                  Хотела предлага Спортен БАР , където можете да се насладите на
                  любимите си мачове. <br /> Налични са три екрана на които се
                  излъчва различен мач. <br /> Можете да резервирате и изберете
                  желания от Вас мач. <br /> Насладете се на луксозен интериор,
                  класни напитки и перфекто обслужване.
                </p>

                <SeeMore link="/bar" />
              </Col>
            </Row>
          </Container>
          <Joy />
        </section>
      </main>
      <Footer />
    </>
  )
}

export default Home
