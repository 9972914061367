import { Row, Col, Form } from 'react-bootstrap'
import './index.scss'
import styles from './index.module.scss'
import { Formik } from 'formik'
import * as yup from 'yup'
import SubmitBtn from '../../components/submit-btn'
import VisiblePassword from '../visible-password'
import { Config } from '../../services/config.service'
import authenticate from '../../utils/authenticate'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RegisterForm = ({ onMessage }) => {
  const [enableValidation, setEnableValidation] = useState(false)

  const registerSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, 'Минималната дължина трябва да е 2 символа.')
      .max(255, 'Името не трябва да надвишава 255 симовола')
      .required('Това поле е задължително'),
    surname: yup
      .string()
      .min(2, 'Минималната дължина трябва да е 2 символа.')
      .max(255, 'Фамилията не трябва да надвишава 255 симовола')
      .required('Това поле е задължително'),
    email: yup
      .string()
      .max(255, 'Вашият email не трябва да надвишава 255 символа')
      .email('Невалиден email')
      .required('Това поле е задължително'),
    phone: yup
      .string()
      .min(10, 'Номерът трябва да е минимум 10 цифри и да започва с + или 00')
      .max(16, 'Номера не трябва да надвишава 15 цифри')
      .matches(
        /^(?:\+|00)\d+$/,
        'Номерът е невалиден. Трябва да започва с + или 00',
      )
      .required('Това поле е задължително'),
    password: yup
      .string()
      .required('Въведи парола')
      .min(6, 'Минималната дължина на паролата трябва да е 6 символа.'),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Паролите не съвпадат'),
    webTerms: yup
      .bool()
      .oneOf(
        [true],
        'Необходиме е да се съгласите с политиките на хотела за да продължите.',
      ),
    personalDataTerms: yup
      .bool()
      .oneOf(
        [true],
        'Необходимо е да се съгласите с условията за защита на лични данни да продължите.',
      ),
  })

  return (
    <Formik
      initialValues={{
        name: '',
        surname: '',
        email: '',
        phone: '',
        password: '',
        repeatPassword: '',
        webTerms: false,
        personalDataTerms: false,
      }}
      enableReinitialize
      validateOnBlur={enableValidation}
      validateOnChange={enableValidation}
      validationSchema={registerSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)

        authenticate(`${Config.API}users`, values)
          .then((response) => {
            if (!response.ok) {
              throw response
            }

            onMessage('Регистрацията беше успешна!', 'success', 'login')
            resetForm()
          })
          .catch((error) => {
            error.json().then((errorsObject) => {
              console.log(errorsObject)
              const errorList = Object.values(errorsObject.errors)
              onMessage(...errorList.flat(), 'danger')
              setSubmitting(false)
            })
          })
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <Form
          className={styles.input}
          onSubmit={(e) => {
            handleSubmit(e)
            setEnableValidation(true)
          }}
        >
          <Form.Group as={Row} className="justify-content-center">
            <Col sm={9}>
              <Form.Control
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type="text"
                name="name"
                placeholder="Име:"
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback className={styles.feedback} type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="justify-content-center">
            <Col sm={9}>
              <Form.Control
                value={values.surname}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type="text"
                name="surname"
                placeholder="Фамилия:"
                isInvalid={!!errors.surname}
              />

              <Form.Control.Feedback className={styles.feedback} type="invalid">
                {errors.surname}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="justify-content-center">
            <Col sm={9}>
              <Form.Control
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type="email"
                name="email"
                placeholder="* Email:"
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback className={styles.feedback} type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="justify-content-center">
            <Col className={styles.passwordField} sm={9}>
              <Form.Control
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type="tel"
                name="phone"
                placeholder="* Телефон:"
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback className={styles.feedback} type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="justify-content-center">
            <Col className={styles.passwordField} sm={9}>
              <Form.Control
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                isInvalid={!!errors.password}
                id="password"
                type="password"
                name="password"
                placeholder="* Парола:"
              />
              <VisiblePassword />
              <Form.Control.Feedback className={styles.feedback} type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="justify-content-center">
            <Col className={styles.passwordField} sm={9}>
              <Form.Control
                value={values.repeatPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.repeatPassword}
                disabled={isSubmitting}
                type="password"
                name="repeatPassword"
                id="repeat-password"
                placeholder="* Повтори паролата:"
              />
              <VisiblePassword />
              <Form.Control.Feedback className={styles.feedback} type="invalid">
                {errors.repeatPassword}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 mt-4">
            <Col sm={{ span: 9, offset: 2 }}>
              <Form.Check
                checked={values.webTerms}
                onChange={handleChange}
                disabled={isSubmitting}
                name="webTerms"
                label="* Съгласен съм с условията на ползване на този сайт!"
                isInvalid={!!errors.webTerms}
                feedback={errors.webTerms}
                feedbackType="invalid"
              />
            </Col>

            <Col sm={{ span: 9, offset: 2 }}>
              <Form.Check
                className={!!errors.personalDataTerms ?? styles.feedback}
                label="* Съгласен съм с условията на предоставяне на лични данни."
                checked={values.personalDataTerms}
                onChange={handleChange}
                name="personalDataTerms"
                isInvalid={!!errors.personalDataTerms}
                disabled={isSubmitting}
                feedback={errors.personalDataTerms}
                feedbackType="invalid"
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className={styles.formButtons + ' justify-content-end'}
          >
            <Col md={8} lg={6} className={styles.register}>
              <SubmitBtn
                disabled={isSubmitting}
                title="Регистрирай се"
                icon={
                  isSubmitting && (
                    <FontAwesomeIcon className="fa-pulse" icon="spinner" />
                  )
                }
              />
            </Col>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
}

export default RegisterForm
