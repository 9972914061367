import { useState } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import styles from './index.module.scss'
import { Container, Row, Col, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import SubmitBtn from '../../components/submit-btn'
import Room from '../../components/room'
import RoomImage from '../../images/room/01.jpg'
import Joy from '../../components/joy-slogan'

const roomsDb = [
  {
    roomImage: RoomImage,
    roomTitle: 'Стандартна двойна стая',
    roomDetails:
      'Капацитета на помещението е за двама възрастни и едно дете до 3 години.',
    areaSquares: '24',
  },
  {
    roomImage: RoomImage,
    roomTitle: 'Студио с разтегателен диван',
    roomDetails: 'Капацитета на помещението е за трима възрастни.',
    areaSquares: 30,
  },
  {
    roomImage: RoomImage,
    roomTitle: 'Стандартна двойна стая',
    roomDetails:
      'Капацитета на помещението е за двама възрастни и едно дете до 3 години.',
    areaSquares: 24,
  },
]

const RoomsPage = () => {
  const [startDate, setStartDate] = useState(new Date())

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Submit')
  }

  return (
    <>
      <Header />
      <section className={styles.checkAvailability}>
        <Container>
          <Row
            as={Form}
            onSubmit={handleSubmit}
            className="justify-content-center text-center alice"
          >
            <Col as="h2" lg={2}>
              Настаняване
            </Col>

            <Col className={styles.dates} lg={2}>
              <p>Пристигане</p>
              <DatePicker
                dateFormat={'MM.dd.yyyy'}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Col>

            <Col className={styles.dates} lg={2}>
              <p>Заминаване</p>
              <DatePicker
                dateFormat={'MM.dd.yyyy'}
                className={styles.datePicker}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Col>

            <Col className={styles.personsCount} lg={1}>
              <p>Възрастни</p>
              <input type="number" min="1" max="99" />
            </Col>

            <Col className={styles.personsCount} lg={1}>
              <p>Деца</p>
              <input type="number" min="0" max="99" />
            </Col>

            <Col lg={1}>
              <p style={{ opacity: '0' }}>book</p>
              <SubmitBtn title="Провери" />
            </Col>
          </Row>
        </Container>
      </section>

      <Container fluid as="section" className={styles.rooms}>
        <Row className={styles.title + ' justify-content-center'}>
          <Col sm={3} className="text-center" as="h1">
            Стаи
          </Col>
        </Row>

        {roomsDb.map((room, index) => (
          <Room
            key={index}
            position={index}
            roomImage={room.roomImage}
            roomTitle={room.roomTitle}
            roomDetails={room.roomDetails}
            areaSquares={room.areaSquares}
            lastRoom={roomsDb.length - 1 === index && true}
          />
        ))}

        <div className={styles.cheatBorder} />
      </Container>

      <Container>
        <Joy />
      </Container>
      <Footer />
    </>
  )
}
export default RoomsPage
