import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faMoneyBillAlt } from '@fortawesome/free-regular-svg-icons'
import {
  faSignInAlt,
  faEyeSlash,
  faEye,
  faUndo,
  faArrowCircleLeft,
  faUser,
  faGlassMartini,
  faExpandArrowsAlt,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faFacebookF,
  faInstagram,
  faSignInAlt,
  faEyeSlash,
  faEye,
  faUndo,
  faArrowCircleLeft,
  faUser,
  faGlassMartini,
  faExpandArrowsAlt,
  faMoneyBillAlt,
  faSpinner,
)
