import styles from './index.module.scss'
import { Container, Row, Col } from 'react-bootstrap'

const Joy = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={7} className="pb-5">
          <span className={styles.separatorBig} />
        </Col>
      </Row>

      <Row className={styles.conclusion + ' text-center'}>
        <Col className="bikham pb-3" as="p">
          Наслада за душата
        </Col>
      </Row>
    </Container>
  )
}

export default Joy
