import React, { useEffect, useState, useCallback } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import styles from './index.module.scss'
import { Container, Row, Col, Modal, Form, Alert } from 'react-bootstrap'
import DatePicker from 'react-datepicker'

import SportBar from '../../images/sportbar/sportbar.jpg'
import CoverImage1 from '../../images/sportbar/sportbar_cover_1.jpg'
import CoverImage2 from '../../images/sportbar/sportbar_cover_2.jpg'
import CoverImage3 from '../../images/sportbar/sportbar_cover_3.jpg'
import PhotoMenu from '../../images/sportbar/menu.jpg'
import PhotoMenuItem from './../../images/sportbar/menu-slide.jpg'
import Slider from 'react-slick'
import Loading from '../../components/loading'
import { Config } from '../../services/config.service'

const TvScreen = ({ screen, onClick }) => {
  const { image, title, headline } = screen

  return (
    <>
      <div className={styles.tv} onClick={(e) => onClick(e, screen)}>
        <div className={[styles.headline, 'alice'].join(' ')}>
          <strong>{title}:</strong>
          {headline}
        </div>

        <div className={styles.tv_screen}>
          <div>
            <img src={image} alt="" title="" />
          </div>
        </div>
      </div>
    </>
  )
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <div
      className={[styles.slickArrow, styles.slickArrowPrev].join(' ')}
      onClick={onClick}
    />
  )
}
function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <div
      className={[styles.slickArrow, styles.slickArrowNext].join(' ')}
      onClick={onClick}
    />
  )
}

const BarPage = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [screens, setScreens] = useState([])
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [screen, setScreen] = useState(0)
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('success')
  const [errors, setErrors] = useState({})

  const menuImages = [PhotoMenuItem, PhotoMenuItem, PhotoMenuItem]

  const settings = {
    infinite: false,
    arrows: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 2,

    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  }

  const onSeatSelect = (event, seatItem) => {
    event.preventDefault()
    event.stopPropagation()
    let screenList = screens.map((screen) => {
      if (screen.id === seatItem?.screen?.id) {
        screen.seats = screen.seats.map((seat) => {
          if (seat.id === seatItem.id) {
            seat.selected = !seat.selected
          }
          return seat
        })
      } else {
        screen.seats = screen.seats.map((seat) => {
          seat.selected = false
          return seat
        })
      }
      return screen
    })

    setScreens(screenList)
    setScreen(~~seatItem?.screen?.id)

    return false
  }

  const checkReservation = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const formErrors = {
      seats: false,
      free: false,
    }

    const seats = screens
      ?.find((item) => item.id === screen)
      ?.seats?.filter((seat) => seat.selected)
    if (!seats?.length) {
      formErrors.seats = true
      return
    }

    setErrors(formErrors)
    if (Object.keys(formErrors).filter((name) => formErrors[name]).length) {
      return
    }

    getList(seats).then((data) => {
      if (data?.data?.length < seats) {
        setMessage('Някои от местата не са свободни за избраната дата')
        setMessageType('error')
        return
      }

      setLoading(false)
    })

    return false
  }

  const onScreenChange = (event) => {
    setScreen(~~event.target.value)
  }

  const onScreenSelected = (event, screen) => {
    setScreen(~~screen.id)
  }

  const getList = useCallback(
    (seats) => {
      setLoading(true)

      let selectedDate = startDate?.setHours(0, 0, 0, 0)
      selectedDate = +(selectedDate / 1000)

      const url = new URL(Config.API + 'sportbar')
      let params = new URLSearchParams({
        date: selectedDate,
      })
      seats?.map((seat) => params.append('seats[]', seat.id))
      url.search = params

      return fetch(url)
        .then((res) => res.json())
        .catch((e) => {
          setLoading(false)
        })
    },
    [startDate],
  )

  useEffect(() => {
    getList([]).then((data) => {
      const allSeats = data.data ?? []

      let screens = {}
      for (let seat of allSeats) {
        screens[seat.screen.id] = { ...seat.screen, seats: [] }
      }

      screens = Object.values(screens)
      screens = screens
        .map((screen) => {
          screen.seats = allSeats
            .filter((seat) => seat.screen?.id === screen?.id)
            .slice()
          return screen
        })
        .sort((a, b) => a.position - b.position)

      setScreens(screens)
      setScreen(~~screens?.sort((a, b) => a.id - b.id)[0].id)
      setLoading(false)
    })
  }, [getList])

  return (
    <>
      <Header />
      <Container
        fluid="md"
        as="section"
        style={{
          backgroundImage: `url(${SportBar})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Row>
          <Col className={styles.transferTitle + ' text-center'}>
            <h1>Спортен бар</h1>

            <h2>MOONLIGHT</h2>
          </Col>
        </Row>

        <Row className={styles.transferText + ' justify-content-center'}>
          <Col lg={10} className={styles.transferEntry + ' alice'}>
            <p>
              Спортният бар е напълно оборудван мултимедиен център с озвучаване,
              микрофони и всички необходими аудиовизуални екстри. Създаваме
              страхотна атмосфера за любителите на спорта. Разполагаме с 3
              екрана и излъчваме събития от света на футбола, бокса
              Формула&nbsp;1, тениса...
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <Row className={styles.carRent + ' g-0'}>
              <Col>
                <img src={CoverImage1} alt="cover bar" title="" />
              </Col>

              <Col>
                <img src={CoverImage2} alt="cover bar" title="" />
              </Col>

              <Col>
                <img src={CoverImage3} alt="cover bar" title="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className={[styles.programList, 'p-4'].join(' ')}>
        <Row>
          <Col>
            <h3 className="alice text-center">
              Програмата тази седмица
              {loading && <Loading></Loading>}
            </h3>
          </Col>
        </Row>
      </Container>

      {loading && !screens?.length ? null : (
        <>
          <div className={styles.tv_bg}>
            <Container>
              <Row>
                {screens?.map((screen, index) => (
                  <Col
                    key={screen.id || index}
                    lg={6}
                    className="text-center mx-auto"
                  >
                    <TvScreen
                      screen={screen}
                      onClick={onScreenSelected}
                    ></TvScreen>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
          <div className={styles.form_bg}>
            <Container>
              <Row className="justify-content-center">
                <Col lg={8}>
                  <Row className={styles.formTitle + ' alice'}>
                    <Col as="h1">Резервация</Col>
                  </Row>
                  <Form
                    noValidate
                    onSubmit={checkReservation}
                    className={styles.bookingForm}
                  >
                    {!message?.length ? null : (
                      <Row>
                        <Col className="mb-3">
                          <Alert
                            onClose={() => setMessage(null)}
                            dismissible
                            variant={
                              messageType === 'success' ? 'success' : 'danger'
                            }
                          >
                            {message}
                          </Alert>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col xs={5}>
                        <Row>
                          <Col className={styles.dateField}>
                            <Form.Label>Дата:</Form.Label>

                            <div className={styles.datePicker}>
                              <DatePicker
                                popperClassName={styles.calendar}
                                dateFormat="dd.MM.yyyy г."
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                minDate={new Date()}
                                disabled={loading && true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Label>Екран:</Form.Label>{' '}
                            <Form.Select
                              onChange={onScreenChange}
                              className={[
                                styles.dropdown,
                                errors?.screen ? 'is-invalid' : null,
                              ]
                                .filter((item) => item)
                                .join(' ')}
                              value={screen}
                              disabled={loading && true}
                              required
                            >
                              {screens
                                ?.sort((a, b) => a.id - b.id)
                                ?.map((screen, i) => (
                                  <option
                                    key={screen.id || i}
                                    value={screen.id}
                                  >
                                    {screen.title}
                                  </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback
                              type="invalid"
                              className={styles.field_error}
                            >
                              Моля, изберете категория.
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={5} className={styles.form_right_column}>
                        <Row>
                          <Col>
                            <Form.Label>Места:</Form.Label>{' '}
                            <div className={styles.dropdownList}>
                              {screens
                                ?.find((item) => item?.id === screen)
                                ?.seats?.map((seat, index) => (
                                  <label key={seat.id || index}>
                                    {seat.title}
                                    <input
                                      type="checkbox"
                                      checked={seat.selected ? true : false}
                                      onInput={(e) => onSeatSelect(e, seat)}
                                      onChange={(e) => {}}
                                    />
                                    <div></div>
                                  </label>
                                ))}
                            </div>
                            <Form.Control.Feedback
                              type="invalid"
                              className={styles.field_error}
                            >
                              Моля, изберете категория.
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                        <Row>
                          <Col className={styles.seats}>
                            <button type="submit">Резервирай</button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col className="text-center">
                  <div
                    className={[styles.barMap, 'position-relative'].join(' ')}
                  >
                    <div>
                      {screens?.slice(0, 3)?.map((screen, index) => (
                        <div
                          className={styles.barMapScreen}
                          key={screen.id || index}
                        >
                          {screen.title}
                        </div>
                      ))}
                    </div>
                    <div className="seats">
                      {screens?.slice(0, 3)?.map((screen, index) => (
                        <div
                          className={styles.barMapScreenWrap}
                          key={screen.id || index}
                        >
                          {screen?.seats?.slice(0, 21)?.map((seat, index) => (
                            <a
                              href="!#"
                              onClick={(e) => onSeatSelect(e, seat)}
                              key={seat.id || index}
                              className={
                                seat.selected ? styles.barMapSelectSeat : null
                              }
                            >
                              {seat.title}
                            </a>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}

      <Container className={styles.menu}>
        <Row>
          <Col className="text-center">
            <img src={PhotoMenu} alt="" title="" />
            <br />
            <button type="button" onClick={() => setShowMenu(true)}>
              Разгледай
            </button>

            <hr />
            <p className="bikham">Наслада за душата</p>
          </Col>
        </Row>
      </Container>

      <Modal
        dialogClassName={styles.modal}
        show={showMenu}
        size="xl"
        onHide={() => setShowMenu(false)}
      >
        <Modal.Header closeButton className={'border-0'}></Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Container className={styles.modalContainer}>
            <Row>
              <Col className={styles.titleWrapper}>
                <div className={styles.separator} />

                <div className={styles.brandImage}>
                  <h2>Menu</h2>
                </div>

                <div className={styles.separator} />
              </Col>
            </Row>
            <Row className={styles.images}>
              <Col sm={10} className="mx-auto">
                <Slider {...settings}>
                  {menuImages.map((image, i) => (
                    <div key={i} className={styles.photoWrap}>
                      <img src={image} alt="Car" className={styles.photo} />
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  )
}
export default BarPage
