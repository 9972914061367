import { Row, Col, Form } from 'react-bootstrap'
import './index.scss'
import styles from './index.module.scss'
import SubmitBtn from '../submit-btn'
import * as yup from 'yup'
import { Formik } from 'formik'
import VisiblePassword from '../visible-password'
import { useParams } from 'react-router-dom'
import authenticate from '../../utils/authenticate'
import { Config } from '../../services/config.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Schema = yup.object().shape({
  password: yup.string().required('Въведете вашата нова парола'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Паролите не съвпадат.')
    .required(''),
})

const ResetPasswordForm = ({ onMessage }) => {
  const { token, email } = useParams()

  return (
    <Formik
      initialValues={{ password: '', repeatPassword: '' }}
      validationSchema={Schema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        values.email = email
        values.token = token

        authenticate(`${Config.API}users/reset`, values)
          .then((response) => {
            if (!response.ok) {
              throw response
            }
            resetForm()
            onMessage('Паролата е сменена успешно!', 'success', 'login')
          })
          .catch((error) => {
            error.json().then((errorsObject) => {
              const errorList = Object.values(errorsObject.errors)
              onMessage(errorList.flat()[0], 'danger')
              setSubmitting(false)
            })
          })
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit} className={styles.input}>
          <Form.Group as={Row} className="justify-content-center">
            <Col className={styles.passwordField} sm={9}>
              <Form.Control
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.password}
                disabled={isSubmitting}
                type="password"
                name="password"
                placeholder="* Парола:"
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
              <VisiblePassword />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="justify-content-center">
            <Col className={styles.passwordField} sm={9}>
              <Form.Control
                value={values.repeatPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.repeatPassword}
                disabled={isSubmitting}
                type="password"
                name="repeatPassword"
                placeholder="* Парола (Отново):"
              />
              <Form.Control.Feedback type="invalid">
                {errors.repeatPassword}
              </Form.Control.Feedback>
              <VisiblePassword />
            </Col>
            <div className={styles.submit}>
              <SubmitBtn
                disabled={isSubmitting}
                title="Потвърди"
                icon={
                  isSubmitting && (
                    <FontAwesomeIcon className="fa-pulse" icon="spinner" />
                  )
                }
              />
            </div>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
