import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import styles from './index.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import Background from '../../images/gallery/header-bg.jpg'
import Title from '../../components/transparent-title'
import GalleryImage2 from '../../images/gallery/2.jpg'
import GalleryImage3 from '../../images/gallery/3.jpg'
import GalleryImage4 from '../../images/gallery/4.jpg'
import GalleryImage5 from '../../images/gallery/5.jpg'
import GalleryImage6 from '../../images/gallery/6.jpg'
import GalleryImage7 from '../../images/gallery/7.jpg'
import GalleryImage8 from '../../images/gallery/8.jpg'
import GalleryImage9 from '../../images/gallery/9.jpg'
import GalleryImage10 from '../../images/gallery/10.jpg'
import GalleryImage11 from '../../images/gallery/11.jpg'
import Zoom from 'react-medium-image-zoom'

const GalleryPage = () => {
  const props = {
    overlayBgColorEnd: 'rgba(66,66,66,0.85)',
    transitionDuration: 400,
    zoomMargin: 100,
  }

  return (
    <>
      <Header />
      <Container fluid className={styles.headline}>
        <Row>
          <Col
            style={{
              backgroundImage: `url(${Background})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              position: 'relative',
              height: '80vh',
              minHeight: '400px',
              padding: '0',
            }}
          >
            <Title middleTitle="Галерия" />
          </Col>
        </Row>
      </Container>

      <Container className={styles.gallery + ' mb-6'}>
        <Row>
          <Col>
            <Row className={styles.firstRow + ' g-0'}>
              <Col className={styles.galleryImage}>
                <Zoom {...props}>
                  <img
                    width="100%"
                    className={styles.img_show}
                    src={GalleryImage2}
                    alt="img-2"
                  />
                </Zoom>
              </Col>

              <Col className={styles.galleryImage}>
                <Zoom {...props}>
                  <img src={GalleryImage3} width="100%" alt="img-3" />
                </Zoom>
              </Col>
            </Row>

            <Row className={styles.secondRow + ' g-0'}>
              <Col className={styles.galleryImage}>
                <Zoom {...props}>
                  <img src={GalleryImage4} width="100%" alt="img-4" />
                </Zoom>
              </Col>
              <Col className={styles.galleryImage}>
                <Zoom {...props}>
                  <img src={GalleryImage5} width="100%" alt="img-5" />
                </Zoom>
              </Col>
            </Row>
          </Col>

          <Col md={2} className={styles.boxBlue}>
            <div className={styles.sideLine} />
          </Col>
        </Row>

        <Row className={styles.thirdRow + ' g-0 mb-3'}>
          <Col>
            <Zoom {...props}>
              <img src={GalleryImage6} width="100%" alt="img-6" />
            </Zoom>
          </Col>
          <Col>
            <Zoom {...props}>
              <img src={GalleryImage7} width="100%" alt="img-7" />
            </Zoom>
          </Col>
          <Col>
            <Zoom {...props}>
              <img src={GalleryImage8} width="100%" alt="img-8" />
            </Zoom>
          </Col>
        </Row>

        <Row className={styles.lastRow}>
          <Col
            md={8}
            className={[styles.galleryImage, styles.bigImage].join(' ')}
          >
            <Zoom
              overlayBgColorEnd="rgba(66,66,66,0.85)"
              transitionDuration="400"
            >
              <img src={GalleryImage9} width="100%" alt="img-9" />
            </Zoom>
          </Col>

          <Col className="d-flex flex-column justify-content-between">
            <Row>
              <Col className={[styles.galleryImage, styles.small].join(' ')}>
                <Zoom {...props}>
                  <img src={GalleryImage10} width="100%" alt="img-10" />
                </Zoom>
              </Col>
            </Row>

            <Row>
              <Col className={[styles.galleryImage, styles.small].join(' ')}>
                <Zoom {...props}>
                  <img src={GalleryImage11} width="100%" alt="img-11" />
                </Zoom>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}
export default GalleryPage
