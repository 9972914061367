import BorderBox from '../../components/border-box'
import styles from './index.module.scss'

const Title = (props) => {
  return (
    <div className={styles.titleContainer}>
      <div className={styles.textWrapper}>
        <BorderBox
          headline={<h2 className={styles.box_headline}>MOONLIGHT</h2>}
          headlineAlign="left"
          borderLeft={false}
        >
          <p className={[styles.box_content, 'segoe'].join(' ')}>{props.middleTitle}</p>
        </BorderBox>
        <p className={styles.hotelSpaText}>Hotel &amp; Spa</p>
      </div>
    </div>
  )
}

export default Title
