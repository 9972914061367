import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Home from './pages/home'
import Contact from './pages/contact'
import TransferPage from './pages/transfer'
import RestaurantPage from './pages/restaurant'
import BarPage from './pages/bar'
import RoomsPage from './pages/rooms'
import GalleryPage from './pages/gallery'
import AboutPage from './pages/about'
import BookingPage from './pages/confirm'
import RoomDetails from './pages/room-details'
import Dashboard from './pages/dashboard'
import { useContext } from 'react'
import UserContext from './Context'

const Navigation = () => {
  const context = useContext(UserContext)
  const isLoggedIn = context.user && context.user.loggedIn

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/transfer" element={<TransferPage />} />
        <Route path="/bar" element={<BarPage />} />
        <Route path="/rooms" element={<RoomsPage />} />
        <Route path="/restaurant" element={<RestaurantPage />} />
        <Route
          path="/dashboard"
          element={isLoggedIn ? <Dashboard /> : <Navigate to="/" />}
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/confirm" element={<BookingPage />} />
        <Route path="/reset/:token/:email" element={<Home />} />
        <Route path="/room-details" element={<RoomDetails />} />{' '}
        {/* add id after db is ready */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Navigation
