import { createContext } from 'react'

const UserContext = createContext({
  token: '',
  user: null,
  logIn() {},
  logOut() {},
})

export default UserContext
