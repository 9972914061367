import styles from './index.module.scss'


const BorderBox = ({
  children,
  headline = '',
  headlineBottom = '',
  borderLeft = true,
  borderRight = true,
  headlineAlign = 'center', // center, left, right
  headlineAlignBottom = 'center', // center, left, right
}) => {
  return (
    <div className={[
        styles.box,
        styles['headline_align_' + headlineAlign],
        styles['headline_align_bottom_' + headlineAlignBottom],
        headline ? styles['headline'] : null,
        headlineBottom ? styles['headline_bottom'] : null,
        !borderLeft ? styles['skip_left_border'] : null,
        !borderRight ? styles['skip_right_border'] : null,
    ].filter(item => item).join(' ')}>
      {headline
        ? <div className={[styles.headline, styles['top']].join(' ')}>
            {headlineAlign !== 'left' ? <div className={styles.before}></div> : null}
            <div className={styles.content}>{headline}</div>
            {headlineAlign !== 'right' ? <div className={styles.after}></div> : null}

          </div>
        : null}
      {children}

      {headlineBottom
        ? <div className={[styles.headline, styles['bottom']].join(' ')}>
            {headlineAlignBottom !== 'left' ? <div className={styles.before}></div> : null}
            <div className={styles.content}>{headlineBottom}</div>
            {headlineAlignBottom !== 'right' ? <div className={styles.after}></div> : null}
          </div>
        : null}
    </div>
  )
}


export default BorderBox
