const authenticate = (url, body) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body, null, 2),
    }).then((data) => resolve(data))
  })
}

export default authenticate
