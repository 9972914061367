import styles from './index.module.css'
import { Link } from 'react-router-dom'

const SeeMore = ({ title, link }) => {
  return (
    <button className={styles.learnMore}>
      <Link to={link}>{title}</Link>
    </button>
  )
}

SeeMore.defaultProps = {
  title: 'Виж още',
}

export default SeeMore
