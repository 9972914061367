import { Row, Col, Form } from 'react-bootstrap'
import './index.scss'
import styles from './index.module.scss'
import SubmitBtn from '../submit-btn'
import { Formik } from 'formik'
import * as yup from 'yup'
import authenticate from '../../utils/authenticate'
import { Config } from '../../services/config.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Schema = yup.object().shape({
  email: yup
    .string()
    .max(255, 'Вашият email не трябва да надвишава 255 символа')
    .email('Невалиден email')
    .required('Моля въведете вашият email с който сте се регистрирали.'),
})

const ForgottenPassword = ({ onMessage }) => {
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Schema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)

        authenticate(`${Config.API}users/forgot`, values)
          .then((response) => {
            if (response.ok) {
              onMessage('Проверете вашият email!', 'success', 'login')
              resetForm()
            }

            throw response
          })
          .catch((error) => {
            error.json().then((errorsObject) => {
              const errorList = Object.values(errorsObject.errors)
              onMessage(...errorList.flat(), 'danger')
              setSubmitting(false)
            })
          })
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className={styles.input}>
          <Form.Group as={Row} className="justify-content-center">
            <Col sm={9}>
              <Form.Control
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                isInvalid={!!errors.email}
                type="email"
                name="email"
                placeholder="Email:"
              />
              <Form.Control.Feedback className={styles.feedback} type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <div className={styles.submit}>
            <SubmitBtn
              disabled={isSubmitting}
              title="Изпрати"
              icon={
                isSubmitting && (
                  <FontAwesomeIcon className="fa-pulse" icon="spinner" />
                )
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ForgottenPassword
