import Header from '../../components/header'
import Footer from '../../components/footer'
import styles from './index.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import AboutBg from '../../images/about/about-bg.jpg'
import AboutImage from '../../images/about/about.jpg'
import RoomsAndFlats from '../../components/room-and-flats'
import UmbrellaPhoto from '../../images/photo_3.jpg'
import PoolText from '../../images/poolsText.jpg'
import SpaPhoto from '../../images/photo_4.jpg'
import SpaText from '../../images/spaText.jpg'

const AboutPage = () => {
  return (
    <>
      <Header />
      <Container fluid className={styles.header}>
        <Row>
          <Col
            className="p-0"
            style={{
              backgroundImage: `url(${AboutBg})`,
              backgroundRepeat: 'no-repeat',
              position: 'relative',
              height: '115vh',
              minHeight: '400px',
            }}
          >
            <div className={styles.semiTransparentTitle}>
              <h1>За нас</h1>
            </div>

            <Row className="justify-content-end m-0">
              <Col md={6} className={styles.headerEntry}>
                <Row>
                  <Col md={8}>
                    <h2 className="bikham">Наслада за душата</h2>

                    <p>
                      <span>Хотел Moonlight Hotel &amp; Spa</span> е разположен
                      покрай крайбрежието на красива Варна. Очарователна гледка,
                      лек бриз и мирис на море вдъхновяват за едно незабравимо
                      изживяване. В обширната територия от открити и закрити
                      басейни и СПА зона може да се намери място за всеки вкус,
                      възраст и настроение. Очакваме Ви за една пълноценна
                      почивка!
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className={styles.roomImage}>
          <Col className="ms-23" lg={6}>
            <img src={AboutImage} alt="about" />
            <h2 className="bikham">Настаняване</h2>
          </Col>
        </Row>

        <Row>
          <Col md={8}>
            <Row className={styles.main + ' justify-content-end alice'}>
              <Col md={9}>
                <p>
                  Хотелът предлага няколко различни типа настаняване, така че да
                  задоволим търсенето и желанието на всеки наш гост. Има
                  помещения подходящи както за двойки, така и за семейства.
                  Стаите се предлагат в два варианта със спалня или с отделни
                  легла. Предлагаме настаняване в 63 двойни стаи, в 43 от тях е
                  възможно настаняване за двама възрастни и дете или трима
                  възрастни, 13 семейни студия за двама възрастни и две деца и 5
                  апартамента за двама възрастни и две деца.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className="p-0">
        <RoomsAndFlats />
      </Container>

      <Container className={styles.spa}>
        <Row className="align-items-center g-0">
          <Col>
            <img src={UmbrellaPhoto} alt="umbrella" />
          </Col>

          <Col>
            <div className={styles.pools}>
              <img src={PoolText} alt="pool-text" />

              <p>
                На Ваше разположение са 2 големи открити минерални басейна, 2
                открити джакузита, 1 детски басейн и един голям вътрешен басейн
                с минерална вода.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="align-items-center g-0 mb-7">
          <Col>
            <div className={[styles.pools, styles.spaEntry].join(' ')}>
              <img src={SpaText} alt="pool-text" />

              <p>
                Възстановяване на тялото и ума, подобряване на настроението и
                укрепване на организма са нашата мисия. Модерният ни СПА център
                предлага възможности за почивка и отдих.
              </p>
            </div>
          </Col>

          <Col>
            <img src={SpaPhoto} alt="spa" />
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  )
}
export default AboutPage
