import React, { useState } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import styles from './index.module.scss'
import { Container, Row, Col, Form, Modal } from 'react-bootstrap'
import Slider from 'react-slick'
import background from '../../images/restaurant/background.png'
import RestaurantPhoto from '../../images/restaurant/restaurant.jpg'
import Decoration from '../../images/restaurant/decoration.svg'
import SushiPhoto from '../../images/restaurant/sushi.jpg'
import PastaPhoto from '../../images/restaurant/pasta.jpg'
import IcecreamPhoto from '../../images/restaurant/icecream.jpg'
import UramakiPhoto from '../../images/restaurant/uramaki.jpg'
import MenuPhoto from '../../images/restaurant/menu.jpg'
import BookingBackground from '../../images/restaurant/restaurant-background.jpg'
import DatePicker from 'react-datepicker'
import RestaurantSchema from '../../images/restaurant/map.png'
import CheersPhoto from '../../images/restaurant/cheers.jpg'
import GardenPhoto from '../../images/restaurant/garden.jpg'
import PastaEatingPhoto from '../../images/restaurant/pasta-eating.jpg'
import MasterChefPhoto from '../../images/restaurant/master-chef.jpg'
import SlaveChefPhoto from '../../images/restaurant/slave.jpg'
import SushiMenuPhoto from '../../images/restaurant/sushi-menu1.jpg'
import SushiMenuPhoto2 from '../../images/restaurant/sushi-menu2.jpg'
import Joy from '../../components/joy-slogan'
import styless from '../../pages/bar/index.module.scss'

import { registerLocale, setDefaultLocale } from 'react-datepicker'
import bg from 'date-fns/locale/bg'
registerLocale('bg', bg)
setDefaultLocale('bg')

function PrevArrow(props) {
  const { onClick } = props
  return (
    <div
      className={[styless.slickArrow, styless.slickArrowPrev].join(' ')}
      onClick={onClick}
    />
  )
}
function NextArrow(props) {
  const { onClick } = props
  return (
    <div
      className={[styless.slickArrow, styless.slickArrowNext].join(' ')}
      onClick={onClick}
    />
  )
}

const settings = {
  infinite: true,
  arrows: true,
  speed: 500,

  slidesToShow: 2,
  slidesToScroll: 2,

  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
}

const tableList = [
  {
    id: 1,
    title: 'Маса 1',
    seats: 4,
    category: 'Салон',
  },
  {
    id: 2,
    title: 'Маса 2',
    seats: 4,
    category: 'Салон',
  },
  {
    id: 3,
    title: 'Маса 3',
    seats: 4,
    category: 'Салон',
  },
  {
    id: 4,
    title: 'Маса 4',
    seats: 4,
    category: 'Салон',
  },
  {
    id: 5,
    title: 'Маса 5',
    seats: 4,
    category: 'Салон',
  },
  {
    id: 6,
    title: 'Маса 6',
    seats: 2,
    category: 'Салон',
  },
  {
    id: 7,
    title: 'Маса 7',
    seats: 7,
    category: 'Салон',
  },
  {
    id: 8,
    title: 'Маса 8',
    seats: 7,
    category: 'Салон',
  },
  {
    id: 9,
    title: 'Маса 9',
    seats: 8,
    category: 'Салон',
  },
  {
    id: 10,
    title: 'Маса 10',
    seats: 2,
    category: 'Салон',
  },
  {
    id: 11,
    number: 1,
    category: 'Бар',
  },
  {
    id: 12,
    number: 2,
    category: 'Бар',
  },
  {
    id: 13,
    number: 3,
    category: 'Бар',
  },
  {
    id: 14,
    number: 4,
    category: 'Бар',
  },
  {
    id: 15,
    number: 5,
    category: 'Бар',
  },
  {
    id: 16,
    number: 6,
    category: 'Бар',
  },
  {
    id: 17,
    number: 7,
    category: 'Бар',
  },
  {
    id: 18,
    number: 8,
    category: 'Бар',
  },
  {
    id: 19,
    number: 9,
    category: 'Бар',
  },
  {
    id: 20,
    number: 10,
    category: 'Бар',
  },
  {
    id: 21,
    number: 11,
    category: 'Бар',
  },
  {
    id: 22,
    title: 'Маса 11',
    category: 'Тераса',
    seats: 4,
  },
  {
    id: 23,
    title: 'Маса 12',
    category: 'Тераса',
    seats: 4,
  },
  {
    id: 24,
    title: 'Маса 13',
    category: 'Тераса',
    seats: 4,
  },
  {
    id: 25,
    title: 'Маса 14',
    category: 'Тераса',
    seats: 4,
  },
  {
    id: 26,
    title: 'Маса 15',
    category: 'Тераса',
    seats: 4,
  },
  {
    id: 27,
    title: 'Маса 16',
    category: 'Тераса',
    seats: 4,
  },
]

let categoriesList = tableList.map((item) => {
  return item.category
})

categoriesList = [...new Set(categoriesList)].map((item) => {
  return {
    title: item,
    selected: false,
    hidden: false,
  }
})

const RestaurantPage = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [showMenu, setShowMenu] = useState(false)
  const [tables, setTables] = useState(tableList)
  const [categories, setCategories] = useState(categoriesList)
  const [smokers, setSmokers] = useState(false)
  const [folks, setFolks] = useState(1)

  const generateHours = () => {
    const hoursAndMinutes = ['18:00']
    let currentHour = 18

    for (let i = 0; i < 12; i++) {
      let currentMinutes = '30'

      if (i % 2) {
        currentMinutes = '00'
        ++currentHour
      }

      if (currentHour === 24) {
        currentHour = '00'
      }

      const time = `${currentHour}:${currentMinutes}`

      hoursAndMinutes.push(time)
    }

    return hoursAndMinutes.map((time) => {
      return (
        <option key={time} value={time}>
          {time}
        </option>
      )
    })
  }

  const tableClickHandler = (seat) => {
    const result = tables.map((item) => {
      if (seat.category === 'Бар') {
        item.active =
          seat.id === item.id
            ? !item.active
            : item.category === 'Бар'
            ? item.active
            : false
      } else {
        item.active =
          item.id === seat.id && item.category === seat.category
            ? !item.active
            : false
      }

      return item
    })

    setTables(result)

    const selectedItem = tables.find((item) => item.active)
    const barSeats = tables.filter((item) => item.active)

    setCategories(
      categories.map((category) => {
        category.selected = category?.title === selectedItem?.category
        return category
      }),
    )

    selectedItem?.category === 'Тераса' ? setSmokers(true) : setSmokers(false)
    selectedItem?.category === 'Бар'
      ? setFolks(barSeats?.length)
      : setFolks(selectedItem?.seats)
  }

  const onChangeTable = (e) => {
    const isMultiple = e.target.getAttribute('multiple') !== null

    if (isMultiple) {
      const options = Array.from(e.target && e.target.options)
      const values = options
        ?.filter((option) => option.selected)
        ?.map((option) => ~~option.value)

      values.forEach((value) => {
        const seat = tables.find((table) => table.id === value)
        seat && tableClickHandler(seat)
      })
    } else {
      const val = ~~e.target.value
      const seat = tables.find((table) => table.id === val)
      seat && tableClickHandler(seat)
    }
  }

  const onZoneChanged = (e) => {
    setCategories(
      categories.map((category) => {
        category.selected = category.title === e.target.value
        return category
      }),
    )

    const selected = categories.find((item) => item.selected)
    selected.title === 'Тераса' ? setSmokers(true) : setSmokers(false)
  }

  const handleFolksChange = (e) => {
    setFolks(e.target.value)
  }

  return (
    <>
      <Header />
      <Container
        as="section"
        fluid
        className={styles.intro}
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'repeat',
          backgroundSize: '100px',
        }}
      >
        <Container>
          <Row>
            <Col className={styles.restaurantPhoto + ' g-0'}>
              <img src={RestaurantPhoto} alt="restaurant" />
              <div className={styles.transparentEntry + ' alice'}>
                <h2>Ресторант</h2>
                <h2>umami</h2>
                <div className={styles.orangeLogo}>
                  <img src={Decoration} alt="decoration" />
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col className={styles.morePhotos + ' pt-4 g-0'} lg={10}>
              <h2 className="pb-3">
                Umami Japanese Food and Garden предлага авторски ястия,
                вдъхновени от японската кухня, артистично поднесени с
                изключително внимание към всеки детайл.
              </h2>
              <div className={styles.orangeLogo + ' pb-4'}>
                <img src={Decoration} alt="decoration" />
              </div>

              <Row className="g-0">
                <Col>
                  <img src={SushiPhoto} alt="sushi" />
                </Col>
                <Col>
                  <img src={PastaPhoto} alt="pasta" />
                </Col>
                <Col>
                  <img src={IcecreamPhoto} alt="ice-cream" />
                </Col>
                <Col>
                  <img src={UramakiPhoto} alt="uramaki" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="g-0">
        <Row className="g-0">
          <Col className={styles.menu}>
            <img src={MenuPhoto} alt="menu" />
            <button onClick={() => setShowMenu(true)}>Разгледай</button>
          </Col>

          <Modal
            dialogClassName={styles.modal}
            show={showMenu}
            onHide={() => setShowMenu(false)}
          >
            <Modal.Header closeButton className={'border-0'}></Modal.Header>
            <Modal.Body className={styles.modalBody}>
              <Container className={styles.modalContainer}>
                <Row>
                  <Col className={styless.titleWrapper}>
                    <div className={styless.separator} />

                    <div className={styless.brandImage}>
                      <h2>Menu</h2>
                    </div>

                    <div className={styless.separator} />
                  </Col>
                </Row>
                <Row className={styless.images}>
                  <Col sm={10} className="mx-auto">
                    <Slider {...settings}>
                      <div className={styles.photoWrap}>
                        <img
                          className={styles.photo}
                          src={SushiMenuPhoto}
                          alt="menu"
                        />
                      </div>

                      <div className={styles.photoWrap}>
                        <img src={SushiMenuPhoto2} alt="menu" />
                      </div>
                      <div className={styles.photoWrap}>
                        <img src={SushiMenuPhoto2} alt="menu" />
                      </div>
                      <div className={styles.photoWrap}>
                        <img src={SushiMenuPhoto2} alt="menu" />
                      </div>
                    </Slider>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </Row>
      </Container>

      <Container fluid className={styles.bookTable}>
        <Row className="justify-content-center">
          <Col className="text-center pt-6 pb-5" lg={6}>
            <h1>Резервирай маса</h1>
            <p>
              Можете да резервирате желаната маса в нашия ресторант. Можете да
              видите разположението на масите в схемата по долу. След като
              въведете предпочитанията си масата, която желаете ще се оцвети в
              по-тъмен цвят.
            </p>
          </Col>
        </Row>
      </Container>

      <section
        style={{
          backgroundImage: `url(${BookingBackground})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} className="pb-5">
              <Form className={styles.formWrapper + ' mt-7'}>
                <div className={styles.title + ' text-center pt-4 pb-3'}>
                  Резервирай
                </div>

                <Container className={styles.formBody + ' pt-3'}>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Дата:</Form.Label>
                        <div className={styles.datePicker}>
                          <DatePicker
                            dateFormat="dd.MM.yyyy г."
                            minDate={new Date()}
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <Form.Label>Час:</Form.Label>
                        <Form.Select aria-label="Default select example">
                          <option>Избери</option>
                          {generateHours()}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Избери зона:</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={onZoneChanged}
                        >
                          {categories
                            ?.filter((category) => !category.hidden)
                            ?.map((category, i) => {
                              return (
                                <option
                                  selected={category.selected}
                                  key={i}
                                  value={category.title}
                                >
                                  {category.title}
                                </option>
                              )
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <Form.Label>Пушачи:</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          defaultValue="Не"
                        >
                          <option value="">Избери</option>
                          <option selected={smokers} value="Да">
                            Да
                          </option>
                          <option selected={!smokers} value="Не">
                            Не
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Избери маса/място:</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          multiple={
                            categories.find((item) => item.selected)?.title ===
                            'Бар'
                          }
                          value={
                            categories.find((item) => item.selected)?.title ===
                            'Бар'
                              ? tables
                                  .filter((item) => item.active)
                                  .map((item) => ~~item.id)
                              : tables.find((item) => item.active)?.id
                          }
                          onChange={onChangeTable}
                        >
                          <option value="">Избери</option>
                          {tables
                            ?.filter(
                              (item) =>
                                item.category ===
                                (categories.find((cat) => cat.selected)
                                  ?.title || categories[0]?.title),
                            )
                            ?.map((table, i) => {
                              return table.category === 'Бар' ? (
                                <option key={i} value={table.id}>
                                  Стол {table.number}
                                </option>
                              ) : (
                                <option key={i} value={table.id}>
                                  {table.title}
                                </option>
                              )
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={2}>
                      <Form.Group>
                        <Form.Label>Хора:</Form.Label>
                        <Form.Control
                          type="number"
                          value={folks}
                          onChange={handleFolksChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className={styles.formActions}>
                    <button>Резервирай</button>
                  </div>
                </Container>
              </Form>
            </Col>

            <Row>
              <Col className={styles.restaurantMap + ' mb-7 position-relative'}>
                <img src={RestaurantSchema} alt="Schema" />

                <div className={styles.barSeatsContainer}>
                  {tables
                    ?.filter((seats) => seats.category === 'Бар')
                    .map((seat, index) => {
                      return (
                        <div
                          key={seat.id || index}
                          className={[
                            styles.barSeat,
                            seat.active ? styles.barSeatActive : null,
                          ].join(' ')}
                          onClick={() => {
                            tableClickHandler(seat)
                          }}
                        />
                      )
                    })}
                </div>

                <div className={styles.saloonTables}>
                  {[2, 4, 7, 8].map((maxSeats, maxSeatIndex) => (
                    <div key={maxSeatIndex}>
                      {tables
                        ?.filter(
                          (seats) =>
                            seats.category === 'Салон' &&
                            seats.seats === maxSeats,
                        )
                        .map((seat, index) => (
                          <div
                            className={[
                              styles['saloonTable' + maxSeats],
                              seat.active
                                ? styles['saloonTable' + maxSeats + 'Active']
                                : null,
                            ].join(' ')}
                            key={seat.id || index}
                            onClick={() => tableClickHandler(seat)}
                          >
                            {seat.title}
                          </div>
                        ))}
                    </div>
                  ))}

                  <div className={styles.barSeatsContainer}>
                    {tables
                      ?.filter((seats) => seats.category === 'Тераса')
                      .map((table, index) => {
                        return (
                          <div
                            key={table.id || index}
                            className={[
                              styles.terraceTables,
                              table.active ? styles.terraceTablesActive : null,
                            ].join(' ')}
                            onClick={() => tableClickHandler(table)}
                          />
                        )
                      })}
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>

        <Container fluid className="g-0">
          <Row className={styles.restaurantPhoto + ' g-0'}>
            <Col>
              <img src={CheersPhoto} alt="cheers" />
            </Col>
            <Col>
              <img src={GardenPhoto} alt="garden" />
            </Col>
            <Col>
              <img src={PastaEatingPhoto} alt="pasta-eating" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className={styles.chefs}>
        <Container>
          <Row>
            <Col>
              <h1 className="pb-1 pt-4">Шеф - готвачи</h1>
              <div className={styles.orangeLogo + ' pb-4'}>
                <img src={Decoration} alt="decoration" />
              </div>
            </Col>
          </Row>

          <Row className={styles.chefPhotos + ' justify-content-center'}>
            <Col md={9}>
              <Row>
                <Col>
                  <div className={styles.chef_frame}>
                    <img src={MasterChefPhoto} alt="chef" />
                  </div>

                  <div className={styles.chefEntry}>
                    <h2>Венцислав Георгиев</h2>

                    <p>
                      През 2007 година се е озовал първа генерация в кулинарната
                      академия HRC. След като завършил академията, стажовете му
                      са в Холандия, в бизнес хотела "Радисън Бул", а в САЩ е
                      работил в "Хилтън Атланта тауърс". Професионалният му път
                      минава още през Южна Корея, Русия - за Олимпийските игри и
                      Лондон - през последните 5 години.
                    </p>
                  </div>
                </Col>

                <Col>
                  <div className={styles.chef_frame}>
                    <img src={SlaveChefPhoto} alt="secondary-chef" />
                  </div>

                  <div className={styles.chefEntry}>
                    <h2>Никол Йорданова</h2>
                    <p>
                      Работила е като pastry chef(майстор сладкар) в Лондон, САЩ
                      и България, а пътищата им с шеф Ангелов се пресичат през
                      май 2017 и от тогава тя е су-шеф сладкар в неговия
                      ресторант. Милена споделя, че Виктор е много взискателен
                      към работата си, но точно това е качеството, което
                      адмирира у него, според нея, такъв трябва да бъде един
                      истински лидер.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <div className="py-5">
              <Joy />
            </div>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}
export default RestaurantPage
