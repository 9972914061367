import styles from './index.module.scss'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Container, Row, Col } from 'react-bootstrap'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Car = ({
  id,
  carImage,
  alt,
  btnName,
  brand,
  model,
  year,
  imageTitle,
  carDetailsImages,
  onClick,
}) => {
  const [show, setShow] = useState(false)

  const settings = {
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  }

  return (
    <>
      <Modal
        dialogClassName={styles.modal}
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton className={'border-0'}></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col className={styles.titleWrapper}>
                <div className={styles.separator} />

                <div className={styles.brandImage}>
                  <img src={imageTitle} alt="car-heading" />
                </div>

                <div className={styles.separator} />
              </Col>
            </Row>

            <Row className={styles.brandTitle + ' text-center alice'}>
              <Col>
                <h2>
                  {brand} {model} {year + ' г.'}
                </h2>
              </Col>
            </Row>

            <Row className={styles.carDetailsImages}>
              <Slider {...settings}>
                {carDetailsImages.map((carImage, i) => (
                  <Col className={styles.sliderImage} key={i}>
                    <img src={carImage} alt="car" />
                  </Col>
                ))}
              </Slider>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <div
        className={styles.carImage}
        onClick={(event) => {
          onClick(event, id)
        }}
      >
        <img src={carImage} alt={alt} />
        <button
          onClick={(e) => {
            e.stopPropagation()
            setShow(true)
          }}
          className={styles.btn + ' alice'}
        >
          {btnName}
        </button>
      </div>

      <div className={styles.carInfo + ' alice'}>
        <p>
          {brand} {model}
        </p>
        <p>{year}г.</p>
      </div>
    </>
  )
}

Car.defaultProps = {
  btnName: 'Виж',
  onClick: (event, carId) => {},
}

export default Car
