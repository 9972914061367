import styles from './index.module.scss'
import Footer from '../../components/footer'
import SubmitBtn from '../../components/submit-btn'
import Logo from '../../images/LOGO.svg'
import { Container, Row, Col } from 'react-bootstrap'

const PreviosPageData = ({ title }) => {
  return (
    <div className={styles.datWrapper}>
      <Col as="h2" className={[styles.title, styles.previousDataTitle]}>
        {title}
      </Col>
      <div className={styles.data}>
        <p>
          Пристигане: <span>30 Септември</span>
        </p>
        <p>
          Престой: <span>5 нощувки</span>
        </p>
        <p>
          Заминаване: <span>5 Октомври</span>
        </p>
        <p>
          Възрастни: <span>1 възрастен</span>
        </p>
        <p>
          Деца: <span>0</span>
        </p>
        <p>
          Тип стая: <span>Стандартна стая</span>
        </p>
        <p>
          Тарифа: <span>1 + 0</span>
        </p>
        <p>ОБЩО: 1100 лв.</p>

        <div className={styles.btnConfirm}>
          <SubmitBtn title="Създай резервация" />
        </div>
      </div>
    </div>
  )
}

const BookingPage = () => {
  return (
    <>
      <Container fluid as="header" className={styles.header + ' p-4'}>
        <Row>
          <Col className={styles.logo}>
            <img src={Logo} alt="logo" />
          </Col>
        </Row>
      </Container>

      <Container as="main" className={styles.main}>
        <Row className="text-center">
          <Col>
            <div className={styles.separator} />
            <h1>Резервация</h1>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col className="text-center mb-6" md={12} lg={6}>
            <PreviosPageData title="Избрана стая" />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default BookingPage
