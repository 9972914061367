import styles from './index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

const VisiblePassword = () => {
  const [icon, setIcon] = useState('eye-slash')

  const showAndHidePassword = (e) => {
    const parent = e.target.parentNode

    for (let child in parent.childNodes) {
      let item = parent.childNodes[child]

      if (item.tagName?.toUpperCase() === 'INPUT') {
        if (item.type === 'password') {
          item.type = 'text'
          setIcon('eye')
        } else {
          item.type = 'password'
          setIcon('eye-slash')
        }
        return
      }
    }
  }

  return (
    <div className={styles.parent} onClick={showAndHidePassword}>
      <FontAwesomeIcon className={styles.svg} size="lg" icon={icon} />
    </div>
  )
}

export default VisiblePassword
