import { Row, Col, Form } from 'react-bootstrap'
import './index.scss'
import styles from './index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubmitBtn from '../../components/submit-btn'
import { Formik } from 'formik'
import * as yup from 'yup'
import VisiblePassword from '../visible-password'
import { useContext, useState } from 'react'
import UserContext from '../../Context'
import authenticate from '../../utils/authenticate'
import { Config } from '../../services/config.service'

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Въведеният email е невалиден')
    .required('Това поле е задължително !'),
  password: yup.string().required('Не може да влезете без парола !'),
})

const LoginForm = ({ onForgot, onMessage }) => {
  const context = useContext(UserContext)
  const [validate, setValidate] = useState(false)

  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginSchema}
        validateOnBlur={validate}
        validateOnChange={validate}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)

          authenticate(`${Config.API}users/token`, values)
            .then((response) => {
              if (!response.ok) {
                throw response
              }

              response.json().then((data) => {
                context.logIn(data)

                localStorage.setItem('user', JSON.stringify(data))
                onMessage('Влязохте успешно!', 'success', '/dashboard')
              })
            })
            .catch((error) => {
              error.json().then((errorsObject) => {
                const errorList = Object.values(errorsObject.errors)

                onMessage(errorList.flat()[0], 'danger')
                setSubmitting(false)
              })
            })
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            noValidate
            className={styles.input}
            onSubmit={(e) => {
              handleSubmit(e)
              setValidate(true)
            }}
          >
            <Form.Group as={Row} className="justify-content-center">
              <Col sm={9}>
                <Form.Control
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  type="email"
                  name="email"
                  placeholder="Email:"
                  isInvalid={!!errors.email}
                  disabled={isSubmitting}
                />
                <Form.Control.Feedback
                  className={styles.feedback}
                  type="invalid"
                >
                  {errors.email || 'Моля въведете валиден email'}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="justify-content-center">
              <Col className={styles.passwordField} sm={9}>
                <VisiblePassword />
                <Form.Control
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Парола:"
                  disabled={isSubmitting}
                  isInvalid={!!errors.password}
                />

                <Form.Control.Feedback
                  className={styles.feedback}
                  type="invalid"
                >
                  {errors.password}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className={styles.formButtons + ' justify-content-end'}
            >
              <Col sm={10} lg={8} xl={6} className={styles.forgottenPassword}>
                <Col as="p" onClick={() => onForgot()}>
                  <FontAwesomeIcon size="xs" icon="undo" />
                  Забравена парола
                </Col>

                <SubmitBtn
                  disabled={isSubmitting}
                  className={styles.loginBtn}
                  title="Вход"
                  icon={
                    isSubmitting && (
                      <FontAwesomeIcon className="fa-pulse" icon="spinner" />
                    )
                  }
                />
              </Col>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default LoginForm
