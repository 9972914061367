import styles from './index.module.scss'
import { Row, Col } from 'react-bootstrap'
import AreaIcon from '../../images/room/area-icon.svg'
import PersonIcon from '../../images/room/person-icon.svg'
import ViewBtn from '../../components/submit-btn'

const Room = ({
  position,
  roomImage,
  roomTitle,
  roomDetails,
  areaSquares,
  lastRoom,
}) => {
  return (
    <Row className={[styles.roomContainer, 'g-0', lastRoom && styles.lastRoom]}>
      <Col className={!(position % 2) ? 'order-0' : 'order-1'} md={7}>
        <div className={styles.image}>
          <img src={roomImage} alt="room" />
        </div>
      </Col>
      <Col
        className={[!(position % 2) ? 'order-1' : 'order-0', styles.summery]}
        md={5}
      >
        <h1>{roomTitle}</h1>
        <p>{roomDetails}</p>
        <Row className={styles.countDetails}>
          <Col>
            <img src={PersonIcon} alt="person-icon" /> 2
          </Col>

          <Col>
            <img src={AreaIcon} alt="area-icon" /> {areaSquares} кв. метра
          </Col>
        </Row>

        <div className={styles.typeView}>
          <p>
            <span></span>Изглед към морето
          </p>
        </div>

        <div className={styles.roomActions}>
          <ViewBtn title="Разгледай" />
        </div>
      </Col>
    </Row>
  )
}

export default Room
