import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap'
import styles from './index.module.scss'
import { useLocation } from 'react-router-dom'
import './bootstrap-rewrite.scss'
import { Link } from 'react-router-dom'
import Logo from '../../images/LOGO.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import Login from '../../components/auth'
import { useParams } from 'react-router-dom'
import UserContext from '../../Context'

const routesLeft = [
  {
    path: '/',
    name: 'Начало',
  },
  {
    path: '/about',
    name: 'За нас',
  },
  {
    path: '/gallery',
    name: 'Галерия',
  },
  {
    path: '/contacts',
    name: 'Контакти',
  },
]

const routesRight = [
  {
    path: '/rooms',
    name: 'Стаи',
  },
  {
    path: '/bar',
    name: 'Бар',
  },
  {
    path: '/restaurant',
    name: 'Ресторант',
  },
  {
    path: '/transfer',
    name: 'Трансфер',
  },
]

const Header = () => {
  let { token } = useParams()

  const location = useLocation()
  const [show, setShow] = useState(false)
  const context = useContext(UserContext)
  const isAuth = context.user && context.user.loggedIn

  return (
    <header>
      <Navbar expand="lg" className={styles.brown}>
        <Container className="justify-content-center">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav defaultActiveKey="/" className="me-auto" />
            <Row className={[styles.nav, 'g-1 align-items-center'].join(' ')}>
              {routesLeft.map((route, index) => (
                <Col key={index}>
                  <Nav.Item key={index}>
                    <Nav.Link
                      active={route.path === location.pathname}
                      as={Link}
                      to={route.path}
                    >
                      {route.name}
                    </Nav.Link>
                  </Nav.Item>
                </Col>
              ))}
              <Col>
                <Navbar.Brand as={Link} to="/">
                  <img
                    src={Logo}
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                  />
                </Navbar.Brand>
              </Col>
              {routesRight.map((route, index) => (
                <Col className={styles.links} key={index}>
                  <Nav.Item key={index}>
                    <Nav.Link
                      active={route.path === location.pathname}
                      as={Link}
                      to={route.path}
                    >
                      {route?.icon} {route.name}
                    </Nav.Link>
                  </Nav.Item>
                </Col>
              ))}
              <Col>
                <Nav.Item>
                  {isAuth ? (
                    <Link to="/dashboard">
                      <button className={styles.loginBtn}>
                        <FontAwesomeIcon size="lg" icon="user" />
                        <span>Профил</span>
                      </button>
                    </Link>
                  ) : (
                    <button
                      className={styles.loginBtn}
                      onClick={() => setShow(true)}
                    >
                      <FontAwesomeIcon size="lg" icon="sign-in-alt" />
                      <span>Вход</span>
                    </button>
                  )}
                </Nav.Item>
              </Col>
            </Row>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {(show || token?.length) && (
        <Login
          token={token}
          hide={() => {
            setShow(false)
            token = null
          }}
        />
      )}
    </header>
  )
}

export default Header
