import { Modal, Container, Row, Col, Alert } from 'react-bootstrap'
import './index.scss'
import styles from './index.module.scss'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RegisterForm from './register'
import LoginForm from './login'
import ForgottenPassword from './forgotten'
import ResetPasswordForm from './reset'
import { Navigate, useNavigate } from 'react-router-dom'

const Auth = ({ hide, token }) => {
  const [navigateTo, setNavigateTo] = useState('')
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('')
  const [redirect, setRedirect] = useState('')
  const [mode, setMode] = useState(token?.length > 0 ? 'reset' : 'login')
  const navigate = useNavigate()

  const onMessageReceived = (message, type, path) => {
    setMessage(message)
    setMessageType(type)
    setRedirect(path)
  }

  useEffect(() => {
    if (message && messageType) {
      const timeout = messageType === 'danger' ? 4000 : 1500

      setTimeout(() => {
        if (messageType === 'danger') {
          setMessage('')
        } else if (messageType === 'success') {
          if (redirect === 'login') {
            setMode('login')
          } else {
            if (redirect) {
              navigate(redirect)
            }
          }
        }
      }, timeout)
    }
  })

  return (
    <Modal
      id="mainModal"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-dialog"
      show={true}
      onHide={() => {
        if (mode === 'reset') {
          setNavigateTo('/')
        }
        hide()
      }}
    >
      {navigateTo?.length > 0 && <Navigate to={navigateTo} />}
      <Modal.Header closeButton className={styles.loginForm}>
        {mode === 'reset' && <h2>Нова парола</h2>}
        {mode === 'login' && <h2>Вход</h2>}
        {mode === 'register' && <h2>Регистрация</h2>}
        {mode === 'forgot' && (
          <div className="position-relative">
            <FontAwesomeIcon
              className="p-0 m-0 border-0  position-absolute"
              style={{ left: 0, top: '10px', cursor: 'pointer' }}
              onClick={() => setMode('login')}
              size="lg"
              color="#eda36d"
              icon="arrow-circle-left"
            />
            <div className={styles.forgotten}>
              <h2>Забравена парола</h2>
            </div>
          </div>
        )}
        <div className={styles.separator}></div>
      </Modal.Header>
      <Modal.Body className={styles.loginBody}>
        {message?.length > 0 && (
          <Row className="justify-content-center">
            <Col sm={9} className="mt-3 mb-0">
              <Alert
                onClose={() => setMessage('')}
                className="text-center"
                style={{ marginBottom: '-15px' }}
                dismissible
                variant={messageType}
              >
                {message}
              </Alert>
            </Col>
          </Row>
        )}

        {['register', 'login'].indexOf(mode) >= 0 && (
          <Container>
            <Row className={[styles.formActions, 'justify-content-center']}>
              <Col
                onClick={() => setMode('login')}
                className={[
                  styles.btnActive,
                  mode === 'login' ? styles.btnActive : styles.btnInactive,
                ]}
                sm={4}
                as="button"
              >
                Вход
              </Col>

              <Col
                onClick={() => {
                  setMode('register')
                  onMessageReceived('', '')
                }}
                className={
                  mode === 'register' ? styles.btnActive : styles.btnInactive
                }
                sm={4}
                as="button"
              >
                Регистрация
              </Col>
            </Row>
          </Container>
        )}

        <Container>
          {mode === 'login' && (
            <LoginForm
              onForgot={() => {
                setMode('forgot')
                onMessageReceived('', '')
              }}
              onMessage={onMessageReceived}
            />
          )}
          {mode === 'register' && (
            <RegisterForm onMessage={onMessageReceived} />
          )}
          {mode === 'forgot' && (
            <ForgottenPassword onMessage={onMessageReceived} />
          )}
          {mode === 'reset' && (
            <ResetPasswordForm onMessage={onMessageReceived} />
          )}
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default Auth
